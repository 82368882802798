<template>
  <div class="wellspaMaterial">
    <img :src="data.url + 'wellspa/bg.png'" alt="" class="bg" />
    <div class="logo">
      <img :src="data.url + 'wellspa/title_2.png'" alt="" />
    </div>
    <div class="title">
      WellSpa iO营销物料大全<br />一站式承包您的日常沟通内容
    </div>
    <div class="areaBox">
      <div class="areaTitle">社交海报</div>
      <div class="areaWrap">
        <div class="row">
          <div class="rowItem" @click="openChangjing">场景海报></div>
          <div class="line"></div>
          <div class="rowItem" @click="openJishi">即时效果海报></div>
        </div>
      </div>
    </div>
    <div class="areaBox">
      <div class="areaTitle">介绍图文</div>
      <div class="areaWrap">
        <div class="row">
          <div class="rowItem" @click="openTool(1)">
            打工人神器 ageLOC WellSpa iO 大揭秘 >
          </div>
        </div>
        <div class="row" @click="openTool(2)">
          <div class="rowItem">脆皮打工人养生“自救”招式大公开 ></div>
        </div>
        <div class="row" @click="openTool(3)">
          <div class="rowItem">科普：揭秘淋巴循环 ></div>
        </div>
      </div>
    </div>
    <div class="areaBox">
      <div class="areaTitle">见证实例</div>
      <div class="areaWrap">
        <div class="row">
          <div class="rowItem" @click="openWeek">12周紧肤效果见证 ></div>
        </div>
      </div>
    </div>
    <div class="areaBox">
      <div class="areaTitle">科普视频</div>
      <div class="areaWrap">
        <div class="row">
          <div class="rowItem" @click="openVideo(1)">
            淋巴系统为什么很重要 >
          </div>
          <div class="rowItem" @click="openVideo(3)">
            为何运动前后都要按摩 >
          </div>
        </div>
        <div class="row">
          <div class="rowItem" @click="openVideo(2)">
            如何一次就能拥有健康美体 >
          </div>
        </div>
      </div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url + 'wellspa/backBtn.png'" alt="" />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { reactive, onMounted } from "vue";
import { baiduTotal } from "../../js/common.js";
import { prviewVideoBox } from "../../js/until";
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
});
const router = useRouter();
//返回首页
let backIndex = () => {
  router.replace("/index");
};
//打工人神器
let openTool = (type) => {
  if (type == 1) {
    window.location.href = `https://mp.weixin.qq.com/s/uo5RhTulTCoUYWGmvft73g`;
  } else if (type == 2) {
    window.location.href = `https://mp.weixin.qq.com/s/WBsBZStRGPa9PDlauGvcuQ`;
  } else if (type == 3) {
    window.location.href = `https://mp.weixin.qq.com/s/iHbAg0JKZ6bW7bUOXqS-Iw`;
  }
};
// 12 周紧肤效果见证
let openWeek = () => {
  prviewVideoBox({
    videoUrl:
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/week.mp4",
    qcodeUrl: "",
    poster: "",
    wangpanPath:
      "https://nudrive.cn.nuskin.com/file/my6i384skek6tua2jz7ntcv4cxq0k5yl# ",
  });
};
//视频见证
let openVideo = (type) => {
  let videoUrl =
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/linba.mp4";
  let wangpanPath =
    "https://nudrive.cn.nuskin.com/file/5e6dikc8badrpqxuu0dqhrtl9ryhdcv8#";
  let qcodeUrl =
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/linbaqr.png";
  if (type == 2) {
    videoUrl =
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/jiankang.mp4";
    wangpanPath =
      "https://nudrive.cn.nuskin.com/file/79jh4k06zapqcad0e67k03p8b420nfhj#";
    qcodeUrl =
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/jiankangqr.png";
  } else if (type == 3) {
    videoUrl =
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/anmo.mp4";
    wangpanPath =
      "https://nudrive.cn.nuskin.com/file/hkipeor1u65pgl6133ygky2dbgohd5mu#";
    qcodeUrl =
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/anmoqr.png";
  }
  prviewVideoBox({
    videoUrl: videoUrl,
    qcodeUrl: qcodeUrl,
    poster: "",
    wangpanPath: wangpanPath,
  });
};
//打开场景
let openChangjing = () => {
  window.location.href = `https://mp.weixin.qq.com/s/8U3H_r8hqX4yz6W0hf33BQ`;
};
//打开即时
let openJishi = () => {
  window.location.href = `https://mp.weixin.qq.com/s/RYdcwIxNwuoLNQaQrQrt4Q`;
};
onMounted(() => {
  baiduTotal();
});
</script>
<style lang="scss" scoped>
.wellspaMaterial {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 0.5rem 0.12rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #e6e7e9;
  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .logo {
    width: 1.95rem;
    overflow: hidden;
    position: relative;
    z-index: 10;
    font-size: 0;
    img {
      max-width: 100%;
    }
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #7e8da1;
    letter-spacing: 1px;
    line-height: 0.18rem;
    margin: 0.25rem 0 0.4rem;
    position: relative;
    z-index: 2;
  }
  .areaBox {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 0.09rem;
    margin-bottom: 0.12rem;
    background: linear-gradient(
      90deg,
      #7e8da1 0%,
      #b2bac5 50.14%,
      #7e8da1 100%
    );
    .areaTitle {
      font-family: Source Han Serif CN, Source Han Serif CN;
      // font-weight: bold;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
      letter-spacing: 0.04rem;
      text-shadow: 0px 2px 2px #7e8da1;
      text-align: center;
    }
    .areaWrap {
      margin-top: 0.1rem;
      width: auto;
      border-radius: 0.1rem;
      background: linear-gradient(90deg, #cdd0d7 0%, #fff 49.85%, #cdd0d7 100%);
    }
    .row {
      width: 100%;
      box-sizing: border-box;
      height: 0.46rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #d3d1d0;
      &:last-child {
        border-bottom: 0px;
      }
      .rowItem {
        flex: 1;
        color: #000;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.12rem;
        font-weight: 400;
        line-height: 0.15rem; /* 125% */
      }
      .line {
        width: 1px;
        height: 0.3rem;
        background: #d3d1d0;
      }
    }
  }
}
</style>
